import { useState, createContext, useContext, useRef, useEffect } from 'react';
import { createStore, setStoreItem } from '../../../../store';
import { ShareContext } from '../share-state';
import { SessionContext } from '../session-provider';
import { GlobalContext } from '../global-provider';
import { randomHexstring, getFileType, createAuditBody } from '../../utils';
import { IsDesktop } from '../../../shared/utils';
import AssetService from '../../../../services/api/assetsService';
import SolutionManifest from '../../../../solution-manifest';
import MediaManager from '../../../../services/api/media/mediaManager';
import path from '../../routePaths';
import Localization from '../../localization';
import ServiceUtil from '../../../../services/util/serviceUtil';
import AWS from 'aws-sdk';
import auditTrailService from '../../../../services/api/auditTrailService';

export const UploadContext = createContext();

export const UploadProvider = ({children}) => {
    const [tempfilesData, setTempFilesData] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [filesArr, setFilesArr] = useState([]);
    const [uploadError, setUploadError] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [successPercentage, setSuccessPercentage] = useState(0);
    const [errorPercentage, setErrorPercentage] = useState(0);
    const [offcanvasState, setOffcanvasState] = useState(false);
    const [progressState, setProgressState] = useState(false);
    const [isUploadSuccess, setUploadSuccess] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [checkedFiles, setCheckedFiles] = useState([]);
    const [uploadBtn, setUploadBtn] = useState(true);
    const [disableUploadBtn, setDisableUploadBtn] = useState(true);
    const [stickyCanvas, setStickyCanvas] = useState(false);
    const [stickySetting, setStickySetting] = useState(false);
    const [activeSetting, setActiveSetting] = useState("");
    const [activeSettingView, setActiveSettingView] = useState("grid");

    // Upload Collection
    const [collection, setCollection] = useState([]);
    const [collectionKey, setCollectionKey] = useState(0);
    const [collectionAsset, setCollectionAsset] = useState([]);
    const [collectionMultipleSelection, setCollectionMultipleSelection] = useState([]);

    // Upload Tag
    const [tagAsset, setTagAsset] = useState([]);
    const [tagMultipleSelection, setTagMultipleSelection] = useState([]);

    // Session Context
    const { tenantUuid, userUuid } = useContext(SessionContext);

    // Global Context
    const { updateToastMessage } = useContext(GlobalContext);

    const isDesktop = IsDesktop();

    const stickyRef = useRef(null);
    const settingRef = useRef(null);
    const processRef = useRef(null);
    const videoRef = useRef([]);
    const audioRef = useRef([]);
    const imageRef = useRef([]);
    const pdfRef = useRef([]);
    const collectionDescRef = useRef(null);
    const collectionSameRef = useRef(null);

    // Share Context
    const { updateMenuTabKey,
        setAssetTagFiles,
        setImageSearchUrl,
        setIsLoading,
        setImageSearchLabels } = useContext(ShareContext);

    // Update ActiveSettingView
    const updateActiveSettingView = (newValue) => {
        setActiveSettingView(newValue);

        const divGridElement = document.querySelector('.grid-btn');
        const divListElement = document.querySelector('.list-btn');

        if(!isDesktop) {
             if(newValue !== 'list') {
                 divGridElement.style.display = 'block';
                 divListElement.style.display = 'none';
             } else {
                 divGridElement.style.display = 'none';
                 divListElement.style.display = 'block';
             }
         } else {
             divGridElement.style.display = 'block';
             divListElement.style.display = 'block';
         }
    };

    // Update Collection
    const updateCollection = (newValue) => {
        setCollection(newValue);
    };

    // Update CollectionKey
    const updateCollectionKey = (newValue) => {
        setCollectionKey(newValue);
    };

    // Update CollectionAsset
    const updateCollectionAsset = (newValue) => {
        setCollectionAsset(newValue);
    };

    // Update CollectionMultipleSelection
    const updateCollectionMultipleSelection = (newValue) => {
        setCollectionMultipleSelection(newValue);
    };

    // Update File Collection
    const updateFileCollection = (updateCollection, checkedFiles) => {
        const updatedFiles = filesData.files;

        if(updateCollection) {
            updatedFiles.forEach((file) => {
                checkedFiles.forEach((checkedFile) => {
                    if(file.id === checkedFile.id) {
                        file.collection = updateCollection;
                    }
                })
                if (file.collection !== undefined && file.collection.length === 0) {
                    delete file.collection;
                }
            });
        }

        updateFileData(updatedFiles);
    };

    // Update DisableUploadBtn
    const updateDisableUploadBtn = (newValue) => {
        setDisableUploadBtn(newValue);
    };

    // Update Upload Btn
    const updateUploadBtn = (newValue) => {
        setUploadBtn(newValue);
    };

    // Update File Data
    const updateFileData = (newValue) => {
        const newFilesData = {
            files: newValue,
            total: newValue.length
        };
        setFilesArr(newValue);
        setFilesData(newFilesData);
    }

    // Update StickySetting State
    const updateOffcanvasState = (newValue) => {
        setOffcanvasState(newValue)
    };

    // Update StickySetting State
    const updateActiveSetting = (newValue) => {
        setActiveSetting(newValue);

        if(newValue === "") {
            setOffcanvasState(false);
            // if(settingRef.current.style.maxWidth !== null) {
                if(settingRef.current){
                    settingRef.current.style.maxWidth = null;
                }
            // }
        }
        const divElement = document.querySelector('.headroom-wrapper');

        if(!isDesktop) {
            divElement.style.display = newValue === '' ? 'block' : 'none';
        }
    };

    // Update StickySetting State
    const updateStickySetting = (newValue) => {
        setStickySetting(newValue)
    };

    // Update SelectAllChecked State
    const updateSelectAllChecked = (newValue) => {
        setSelectAllChecked(newValue)
    };

    // Update SelectAllChecked State
    const updateStickyCanvas = (newValue) => {
        setStickyCanvas(newValue)
    };

    // Update UploadError State
    const updateUploadError = (newValue) => {
        setUploadError(newValue);
    };

    // Update CheckedFiles State
    const updateCheckedFiles = (newValue) => {
        setCheckedFiles(newValue);
    };

    /* Map Assets with Collection */
    const mapCollection = async (data) => {
        const bodyMapAssets = {
            "input": {
                "map": data,
                "tenantUuid": tenantUuid
            }
        }

        try {
            const mapAssets = await AssetService.mapAssets(bodyMapAssets, {}, tenantUuid);
            return mapAssets;
        } catch (error) {
            console.error(`fail to map assets to collection`);
            return false;
        }
    };

    // Update Files UUID
    const updateUUID = (id, uuid) => {
        const updateFilesUUID = filesData.files;
        const filterUpdateFiles = updateFilesUUID.filter(file => file.id === id);
        updateFilesUUID.forEach(file => file.id === id ? file.uuid = uuid : null)

        if(filterUpdateFiles[0].collection) {
            const dataCollection = [
                {
                    "uuid": filterUpdateFiles[0].uuid,
                    "collections": filterUpdateFiles[0].collection
                }
            ];

            mapCollection(dataCollection);
        }

        const newFilesData = {
            files: updateFilesUUID,
            total: updateFilesUUID.length
        };

        setFilesData(newFilesData);
    };

    const handleVideoJobCompletion = async (rekog, jobId) => {
        try {
            while (true) {
                const data = await rekog.getLabelDetection({ JobId: jobId }).promise();
                const jobStatus = data.JobStatus;

                if (jobStatus === 'SUCCEEDED') {
                    const labels = data.Labels;
                    return labels; // Return labels when job succeeds
                } else if (jobStatus !== 'IN_PROGRESS') {
                    throw new Error("Label detection job failed or was aborted.");
                }

                // Wait for 5 seconds before checking again
                await new Promise(resolve => setTimeout(resolve, 2500));
            }
        } catch (error) {
            throw error; // Re-throw error to handle it at a higher level if needed
        }
    };

    // async function getLabels(rekog, jobId) {
    //     return new Promise((resolve, reject) => {
    //       rekog.getLabelDetection({ JobId: jobId }, (err, data) => {
    //         if (err) {
    //           reject(err);
    //         } else {
    //           const labels = data.Labels;
    //           resolve(labels);
    //         }
    //       });
    //     });
    // }

    //Handle Analysis
    const handleAnalysis = async (bucket, name, type) => {
        const maxLabels = 30;

        function getTop30Labels(labels, type) {
            // Sort labels based on confidence level in descending order
            labels.sort((a, b) => (type === 'video' ? b.Label.Confidence - a.Label.Confidence : b.Confidence - a.Confidence));

            // Slice the array to get the top 30 labels
            const topLabels = labels.slice(0, maxLabels);

            topLabels.sort((a, b) => (type === 'video' ? a.Label.Name.localeCompare(b.Label.Name) : a.Name.localeCompare(b.Name)));

            return topLabels;
        }

        try {
            // Validate input parameters
            if (!bucket || !name || !type) {
                throw new Error('Bucket, name, and type are required.');
            }

            // Initialize AWS Rekognition
            const rekognition = new AWS.Rekognition();

            // Set parameters based on type
            const params = {
                MinConfidence: SolutionManifest.AIML.minConfidence,
            };

            if (type === 'image') {
                params.Image = { S3Object: { Bucket: bucket, Name: name } };
                // Detect labels
                const detectLabels = await rekognition.detectLabels(params).promise();
                const top30Labels = getTop30Labels(detectLabels.Labels, type);

                return top30Labels;
            // } else if (type === 'video') {
            //     params.Video = { S3Object: { Bucket: bucket, Name: name } };
            //     const startLabelDetection = await rekognition.startLabelDetection(params).promise();

            //     const videoLabels = await handleVideoJobCompletion(rekognition, startLabelDetection.JobId);

            //     const uniqueVideoLabels = videoLabels.reduce((uniqueLabels, { Label }) => {
            //         const existingIndex = uniqueLabels.findIndex(item => item.Label.Name === Label.Name);
            //         if (existingIndex === -1) {
            //             uniqueLabels.push({ Label: { Name: Label.Name, Confidence: Label.Confidence } });
            //         }
            //         return uniqueLabels;
            //     }, []);

            //     const top30Labels = getTop30Labels(uniqueVideoLabels, type);

            //     return top30Labels;
            }
        } catch (error) {
            console.error('Error analyzing image:', error.message);
            return []; // Return an empty array if an error occurs
        }
    };

    // Upload Files
    const uploadFilesData = async (files, uploadRemainingSize) => {
        const allowedExtensions = SolutionManifest.allowedExtensions;
        const tempFiles = [];
        const offset = 250;
        const { scrollTop } = document.documentElement;
        
        let currentFilesData = [];
        let sticky = offset,
            settingScrollTop;

        const isUploadSizeExceeded = (files) => {
            const units = ['Bytes', 'KB', 'MB', 'GB'];
            const sizeMatch = uploadRemainingSize.match(/^(\d+(\.\d+)?)\s*([a-zA-Z]+)$/);

            const [, size, , unit] = sizeMatch;
            const remainingSizeInBytes = parseFloat(size) * Math.pow(1024, units.indexOf(unit.toUpperCase()));
            const totalFileSizeInBytes = filesArr.length > 0 ? filesArr.reduce((total, file) => total + file.object.size, 0) : files.reduce((total, file) => total + file.size, 0);

            // Check if total file size is less than or equal to remaining size
            return totalFileSizeInBytes >= remainingSizeInBytes;
        };

        // Sticky Function
        if (stickyRef.current) {
            if (scrollTop >= sticky) {
                setStickyCanvas(true);
            } else {
                setStickyCanvas(false);
            }
        }

        if(processRef.current) {
            settingScrollTop = processRef.current.offsetTop + offset - 80;

            if(scrollTop >= settingScrollTop) {
                setStickySetting(true);
            } else {
                setStickySetting(false);
            }
        }

        // Reset Checked files if uploaded default length is 0
        if(filesArr.length === 0) {
            setSelectAllChecked(false);
            setCheckedFiles([]);
        }

        // Checking Upload File Size Exceeded Storage or not
        if (isUploadSizeExceeded(files)) {
            // Handle case where file size exceeds remaining size
            updateToastMessage(Localization.Upload.StorageExceeded, 'error');

            return;
        };

        for(const file of files) {
            const sameFilePath = filesArr.some(uploadedFile => uploadedFile.object.path === file.path);
            const fileSize = file.size / 1024 / 1024; // in MiB

            if(!sameFilePath && allowedExtensions.exec(file.name) && fileSize <= SolutionManifest.UploadLimit.Size && file.name.length < SolutionManifest.UploadLimit.Name) {
                const attributeList = [
                    { key: 'group', value: tenantUuid},
                    { key: 'userUuid', value: userUuid},
                    { key: 'tags', value: ""},
                ];
                const attributes = attributeList.reduce((a0, c0) => ({
                    ...a0,
                    [c0.key]: c0.value,
                }), {});
                
                filesArr.unshift({
                    aiSmartTags: "",
                    name: file.name,
                    type: getFileType(file.name),
                    object: file,
                    id: getFileType(file.name) + "-" + randomHexstring(),
                    attributes: attributes,
                    state: getFileType(file.name) === 'image' ? 'ANALYSING' : '',
                    status: 'INGEST_STARTED',
                    uuid: '',
                    timestamp: file.timestamp ? file.timestamp : new Date().getTime()
                });

                currentFilesData.push({
                    aiSmartTags: "",
                    name: file.name,
                    type: getFileType(file.name),
                    object: file,
                    id: getFileType(file.name) + "-" + randomHexstring(),
                    attributes: attributes,
                    state: getFileType(file.name) === 'image' ? 'ANALYSING' : '',
                    status: 'INGEST_STARTED',
                    uuid: '',
                    timestamp: file.timestamp ? file.timestamp : new Date().getTime()
                })
                setFilesArr(filesArr);
            } else {
                tempFiles.push({
                    name: file.name,
                    type: file.type,
                    object: file,
                    attributes: files.attributes,
                    error: sameFilePath ? "same" : !allowedExtensions.exec(file.name) ? "extension" : fileSize > SolutionManifest.UploadLimit.Size ? "size" : file.name.length > SolutionManifest.UploadLimit.Name ? "name" : null
                });
            }
        };

        const newItems = filesData?.files && filesArr.filter(item => !filesData.files.some(existingItem => existingItem.name === item.name));
        const concatItems = filesData.files && [...filesData.files, ...newItems];

        const newFilesData = {
            files: filesData.files ? concatItems : filesArr,
            total: filesData.files ? concatItems.length : filesArr.length
        };

        setFilesData({ files: newFilesData.files, total: newFilesData.files.length });

        async function processProxyStateFiles(stateFiles) {
            let assets = [];
            for (let i = 0; i < stateFiles.length; i++) {
                let uuid = ServiceUtil.uuid4();
                const fileType = getFileType(stateFiles[i].name);
                if(fileType === 'image') {
                    await AssetService.uploadProxyS3TempAssets(stateFiles[i].name, stateFiles[i].object, tenantUuid, uuid);
                 
                    const labels = await handleAnalysis(SolutionManifest.Proxy.Bucket, `temp/${tenantUuid}/${uuid}/${stateFiles[i].name}`, fileType);
                   
                    const updatedFilesData = currentFilesData.map(file => {
                        if(file.name === stateFiles[i].name && file.state === 'ANALYSING') {                        
                            if (labels) {
                                const tags = labels.map(label => fileType === 'video' ? label.Label.Name : label.Name).join(',');
                                return {
                                    ...file,
                                    state: '',
                                    attributes: {
                                        ...file.attributes,
                                        tags: tags
                                    },
                                    aiSmartTags: tags,
                                    timestamp: file.timestamp ? file.timestamp : new Date().getTime()
                                };
                            } else {
                                const fileData = filesData.files.find(fileData => fileData.name === file.name);
                                const attributes = fileData.attributes;
                                return {
                                    ...file,
                                    state: '',
                                    attributes: attributes
                                };
                            }
                        } else {
                            return {
                                ...file
                            }
                        }
                    });
                    currentFilesData = updatedFilesData;
                    setAssetTagFiles(prevAssetTagFiles => {
                        const updatedPrevAssetTagFiles = [...prevAssetTagFiles, { key: stateFiles[i].name, labels: labels, type: fileType }];
                        setFilesData((prevFilesData) => {
                            const updatedFiles = prevFilesData.files.map(file => {
                                const updatedFile = updatedFilesData.find(item => item.name === file.name);
                                const fileTags = prevAssetTagFiles.filter(item => item.key === file.name)[0]?.labels.map(label => label.Name).join(',');
                                const updatedOldFile = {
                                    ...file,
                                    attributes: {
                                        ...file.attributes,
                                        tags: file.attributes.tags ? file.attributes.tags : fileTags ? fileTags : ""
                                    },
                                    state: file.attributes.tags || fileTags ? '' : file.state,
                                    aiSmartTags: file.aiSmarttags ? file.aiSmarttags : fileTags
                                };
                             
                                return updatedFile ? {...updatedOldFile, ...updatedFile} : updatedOldFile;
                            });
                            return { files: updatedFiles, total: updatedFiles.length }
                        });
                        return updatedPrevAssetTagFiles;
                    });
                    assets.push({
                        "name": `temp/${tenantUuid}/${uuid}/${stateFiles[i].name}`
                    })
                }
            };
            
            if(assets.length > 0) {
                await AssetService.deleteProxyS3TempAssets(assets, tenantUuid);
            }
        }

        if(tempFiles.length > 0) {
            setFilesData({ files: newFilesData.files, total: newFilesData.total });
            setIsLoading(false);
            setTempFilesData(tempFiles);
            setUploadError(true);
            setOffcanvasState(true);
            setUploadBtn(false);
        } else {
            setTempFilesData([]);
            setUploadBtn(true);
            setIsLoading(false);
            setUploadError(false);
        }
        setSelectAllChecked(false);

        if(currentFilesData.length > 0) {
            await processProxyStateFiles(currentFilesData)
            .then(async () => {
            })
            .catch((err) => {
                const updateFileData = newFilesData.files
                .map(file => {
                    file.state = '';
                    file.timestamp = file.timestamp ? file.timestamp : new Date().getTime();
                    return file;
                });

                setFilesData({ files: updateFileData, total: updateFileData.length });
                console.error('Error processProxyStateFiles:', err);
            });
        };
    };

    // Upload S3 Assets
    const apiUploadFile = async () => {
        const stateFiles = filesData.files;
        const mediaManager = MediaManager.getSingleton();
        setDisableUploadBtn(true);
        setOffcanvasState(false);
        setProgressState(true);
        setActiveSetting("");
        setUploadBtn(false);
        setSelectAllChecked(false);
        setCheckedFiles([]);
        registerMediaManagerEvents(mediaManager);
        stateFiles.forEach(file => file.state = 'UPLOADING');
        if(!isDesktop){
            updateActiveSetting('');
        }
        const options = {
            files: stateFiles.map(file => file.name)
        };

        // Access Upload Apis Call
        await processStateFiles(stateFiles)
            .then(async () => {
                // Add upload asset/s activity to audit trail
                const auditBody = createAuditBody(tenantUuid, userUuid, "asset", "upload", options);
                await auditTrailService.addActivity(auditBody, tenantUuid);

                setTimeout(async () => {
                    await Promise.all([
                      mediaManager.scanProcessingRecords()
                    ]);
                }, 10);

                const newFilesData = {
                    files: stateFiles,
                    total: stateFiles.length
                };
                setFilesData(newFilesData);
                setUploadSuccess(true)
            })
            .catch((err) => {
                console.error('Error processing files:', `${err}: ${stateFiles}`);
            });
    }

    // Loop files & Api call
    async function processStateFiles(stateFiles) {
        for (let i = 0; i < stateFiles.length; i++) {
            if(stateFiles[i].collection && stateFiles[i].collection.length > 0) {
                stateFiles[i].collection.forEach((c) => {
                    setStoreItem(c, [], createStore('collections', 'asset-collections'));
                });
            }
            await AssetService.uploadS3Assets(stateFiles[i].name, stateFiles[i], updateUUID, {}, tenantUuid);
        }
    }

    // Handler Progress
    const handleProgress = async () => {
        setDisableUploadBtn(false);
        setOffcanvasState(false);
        updateMenuTabKey(path.EXPLORE);
        setProgressState(false);
        setPercentage(0);
        setSuccessPercentage(0);
        setErrorPercentage(0);
        setFilesData([]);
        setFilesArr([]);
        setTempFilesData([]);
    };

    // registerMediaManagerEvents
    const registerMediaManagerEvents = async (mediaManager) => {
        mediaManager.eventSource.addEventListener(MediaManager.Event.Media.Updated, async (event) => {
            if(event.detail && event.detail.$data.attributes.group === tenantUuid) {
                if(event.detail && event.detail.$data.status === SolutionManifest.Statuses.IngestStarted) {
                    const updateStatus = filesData.files;
                    await updateStatus.forEach(file => file.uuid === event.detail.$data.uuid ? file.state = SolutionManifest.Statuses.Uploading : null);

                    const newFilesData = {
                        files: updateStatus,
                        total: updateStatus.length
                    };

                    setFilesData(newFilesData);
                }
                else if(event.detail && event.detail.$data.status === SolutionManifest.Statuses.IngestCompleted ||
                    event.detail.$data.status === SolutionManifest.Statuses.AnalysisStarted ||
                    event.detail.$data.status === SolutionManifest.Statuses.AnalysisCompleted) {
                    const updateStatus = filesData.files;
                    await updateStatus.forEach(file => file.uuid === event.detail.$data.uuid ? file.state = SolutionManifest.Statuses.Completed : null);

                    const newFilesData = {
                        files: updateStatus,
                        total: updateStatus.length
                    };

                    const errorFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Error);
                    const completedFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Completed);
                    setFilesData(newFilesData);

                    if(errorFile.length > 0) {
                        const individualSuccessPercentage = completedFile.length / newFilesData.total * 100;
                        const individualErrorPercentage = errorFile.length / newFilesData.total * 100;
                        setSuccessPercentage(Math.trunc(individualSuccessPercentage));
                        setErrorPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                        setPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                    } else {
                        const individualSuccessPercentage = completedFile.length / newFilesData.total * 100;
                        setSuccessPercentage(Math.trunc(individualSuccessPercentage));
                        setPercentage(Math.trunc(individualSuccessPercentage));
                    }
                }
            };
        });
        mediaManager.eventSource.addEventListener(MediaManager.Event.Media.Error, async (event) => {
            if(event.detail && event.detail.$data.attributes.group === tenantUuid) {
                if(event.detail && event.detail.$data.overallStatus === SolutionManifest.Statuses.Error) {
                    const updateStatus = filesData.files;
                    updateStatus.forEach(file => file.uuid === event.detail.$data.uuid ? file.state = event.detail.$data.overallStatus : null);

                    const newFilesData = {
                        files: updateStatus,
                        total: updateStatus.length
                    };

                    const errorFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Error);
                    const completedFile = newFilesData.files.filter(file => file.state === SolutionManifest.Statuses.Completed);
                    setFilesData(newFilesData);

                    if(completedFile.length > 0) {
                        const individualSuccessPercentage = completedFile.length / newFilesData.total * 100;
                        const individualErrorPercentage = errorFile.length / newFilesData.total * 100;

                        setSuccessPercentage(Math.trunc(individualSuccessPercentage));
                        setErrorPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                        setPercentage(Math.trunc(individualSuccessPercentage + individualErrorPercentage));
                    } else {
                        const individualErrorPercentage = errorFile.length / newFilesData.total * 100;
                        setErrorPercentage(Math.trunc(individualErrorPercentage));
                        setPercentage(Math.trunc(individualErrorPercentage));
                    }
                }
            };
        });
    };

    const mergeTagArrays = (arr) => {
        const merged = [].concat(...arr);
        const duplicates = new Set(arr[0]);

        for (let i = 1; i < arr.length; i++) {
            const subarray = arr[i];
            const subarraySet = new Set(subarray);

            for (const string of duplicates) {
                if (!subarraySet.has(string)) {
                    duplicates.delete(string);
                }
            }
        }

        if (Array.from(duplicates).length > 0) {
            const uniqueDuplicates = merged.filter(item => Array.from(duplicates).indexOf(item) === -1);
            const uniqueMerged = [...new Set(uniqueDuplicates)];

            setTagAsset(Array.from(duplicates));
            setTagMultipleSelection(uniqueMerged);
            return duplicates && uniqueMerged;
        } else {
            const uniqueMerged = [...new Set(merged)];
            setTagMultipleSelection(uniqueMerged);
            return merged;
        }
    };

    const getFileLabels = async (file) => {
        let assets = [];
        const uuid = ServiceUtil.uuid4();
        await AssetService.uploadProxyS3TempAssets(file.name, file, tenantUuid, uuid);
             
        const labels = await handleAnalysis(SolutionManifest.Proxy.Bucket, `temp/${tenantUuid}/${uuid}/${file.name}`, getFileType(file.name));
        
        assets.push({
            "name": `temp/${tenantUuid}/${uuid}/${file.name}`
                })
        if(assets.length > 0) {
            await AssetService.deleteProxyS3TempAssets(assets, tenantUuid);
        }

        return labels;
    };
    
    let cancelUpload = false; // Cancellation flag

    // Function to set the cancellation flag
    const cancelUploadProcess = () => {
        cancelUpload = true;
    };

    // Upload Image Files
    const uploadImageFileData = async (files) => {
        const allowedImageExtensions = SolutionManifest.allowedImageExtensions;
        setImageSearchUrl("");
        
        if (files && files.length > 0) {
            const file = files[0];
            const fileSize = file.size / 1024 / 1024; // in MiB

            if (allowedImageExtensions.exec(file.name) === null || fileSize >= SolutionManifest.UploadLimit.Size) {
                return Localization.Upload.ErrorImageUpload;
            }

            const labels = await getFileLabels(file);
            setImageSearchLabels(labels.map(label => label.Name));
            
            const openRequest = indexedDB.open("image-search", 1);
        
            openRequest.onupgradeneeded = function() {
                const db = openRequest.result;
                if (!db.objectStoreNames.contains('imageFile')) {
                    db.createObjectStore('imageFile');
                }
            };
        
            openRequest.onerror = function() {
                console.error("Error", openRequest.error);
            };
            
            if (cancelUpload) {
                return "Cancelled"; // Exit the function if the upload has been cancelled
            }
        
            openRequest.onsuccess = async function() {
                if (cancelUpload) {
                    return "Cancelled"; // Exit the function if the upload has been cancelled
                }
                const db = openRequest.result;
    
                // Convert file to Base64 string
                const reader = new FileReader();
                reader.onloadend = function() {
                    const base64 = reader.result;
                    
                    // Storing Base64 string
                    const transaction = db.transaction("imageFile", "readwrite");
                    const imageFile = transaction.objectStore("imageFile");
                    const request = imageFile.put(base64, "file");
    
                    request.onerror = function() {
                        console.error("Error", request.error);
                    };
                };
                reader.onerror = function(error) {
                    console.error("Error converting to Base64:", error);
                };
                reader.readAsDataURL(file);
            };
            if (cancelUpload) {
                return "Cancelled"; // Exit the function if the upload has been cancelled
            }
            
            return "";
        }
    
        return Localization.Upload.ErrorImageUpload;
    };

    cancelUpload = false;

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [filesData]);


    return (
        <UploadContext.Provider value={{
            mergeTagArrays,
            tagAsset,
            setTagAsset,
            tagMultipleSelection,
            setTagMultipleSelection,
            collectionDescRef,
            collectionSameRef,
            activeSettingView,
            updateActiveSettingView,
            collection,
            collectionKey,
            collectionAsset,
            collectionMultipleSelection,
            updateCollection,
            updateCollectionKey,
            updateCollectionAsset,
            updateCollectionMultipleSelection,
            updateFileCollection,
            updateDisableUploadBtn,
            updateUploadBtn,
            updateFileData,
            updateOffcanvasState,
            updateActiveSetting,
            updateStickySetting,
            updateStickyCanvas,
            updateUploadError,
            updateSelectAllChecked,
            updateCheckedFiles,
            activeSetting,
            handleProgress,
            apiUploadFile,
            stickySetting,
            selectAllChecked,
            checkedFiles,
            uploadBtn,
            disableUploadBtn,
            stickyCanvas,
            uploadFilesData,
            uploadImageFileData,
            cancelUploadProcess,
            getFileLabels,
            videoRef,
            audioRef,
            imageRef,
            pdfRef,
            stickyRef,
            settingRef,
            processRef,
            tempfilesData,
            filesData,
            uploadError,
            percentage,
            successPercentage,
            errorPercentage,
            offcanvasState,
            progressState,
            setProgressState,
            isUploadSuccess
        }}>
            {children}
        </UploadContext.Provider>
    );
};
